.main-footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
}

button {
    margin-left: 5px;
}

.table-actions {
    width: 150px;
}